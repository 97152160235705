// ** React Imports
import React, { Suspense, lazy } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

import { QueryClientProvider } from "react-query";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { queryClient } from "./configs/queryClientConfigs";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

// Axios Mock Adapter
if (
  process.env.REACT_APP_MOCK_BE === "true" ||
  process.env.REACT_APP_MOCK_AUTH === "true"
) {
  require("./@fake-db/db");
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <Suspense fallback={<div className="w-100 h-100 bg-danger">Hello</div>}> */}
      <Suspense
        fallback={
          <div className="d-flex h-100 w-100 align-items-center justify-content-center">
            <Spinner className=" m-auto  " color="primary" />
          </div>
        }
      >
        <QueryClientProvider client={queryClient}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </QueryClientProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>
);

// ReactDOM.render(
//   <Provider store={store}>
//     {/* <Suspense fallback={<div className="w-100 h-100 bg-danger">Hello</div>}> */}
//     <Suspense
//       fallback={
//         <div className="d-flex h-100 w-100 align-items-center justify-content-center">
//           <Spinner className=" m-auto  " color="primary" />
//         </div>
//       }
//     >
//       <QueryClientProvider client={queryClient}>
//         <ThemeContext>
//           <LazyApp />
//           <ToastContainer newestOnTop />
//         </ThemeContext>
//       </QueryClientProvider>
//     </Suspense>
//   </Provider>,
//   document.getElementById("root")
// );
// ReactDOM.render(<Spinner />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
